import { useEffect, useState, createContext } from 'react'
import { apiCall } from '@utils'

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loadingUser, setLoadingUser] = useState(true)
  const [permissions, setPermissions] = useState(null)

  const refreshToken = async () => {
    try {
      setLoadingUser(true)
      const result = await apiCall('/user/auth/refresh_token')
      if (result.user) {
        result.user.token = result.token
        setUser(result.user)
      }
    } finally {
      setLoadingUser(false)
    }
  }

  // refresh user token on every page refresh and at interval
  useEffect(() => {
    refreshToken()

    const refreshInterval = 1000 * 60 * 59 // milliseconds * seconds * minutes
    setInterval(() => {
      refreshToken()
    }, refreshInterval)
  }, [])

  return (
    <UserContext.Provider
      value={{ user, setUser, loadingUser, setLoadingUser, permissions, setPermissions, refreshToken }}
    >
      {children}
    </UserContext.Provider>
  )
}
